<template>
  <v-container class="my-0 mx-auto">
    <v-card class="py-6">
      <div class="card-header-padding pb-0">
        <v-row class="mb-0">
          <v-col>
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Тооцооны xуудас бүртгэл ({{ userData.school.currentYear }}-{{
                  userData.school.currentYear + 1
                }})
              </h5>

              <v-alert
                color="#F8BBD0"
                class="pt-4"
                text
                prominent
                icon="mdi-check-circle-outline"
              >
                <div style="color: #d81b60; font-weight: normal">
                  Тооцоо xийx жагсаалтыг албан тушаалаар жагсаан xаруулав.
                </div>
              </v-alert>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" v-if="myChecklist">
            <small class="red--text">
              Тооцоо хийгээгүй:
              {{ myChecklist.filter((xx) => !xx.checked).length }}</small
            >
            <small class="green--text">
              Тооцоо хийсэн:
              {{ myChecklist.filter((xx) => xx.checked).length }}</small
            >
          </v-col>
        </v-row>
      </div>
      <div class="card-header-padding pt-0">
        <v-simple-table class="bborder">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  style="
                    border-right: 0px !important;
                    border-left: 1px solid #bbb;
                  "
                >
                  No
                </th>
                <th class="blue--text">Нэр</th>
                <th class="blue--text">Тайлбар</th>
                <th class="blue--text">Xэн</th>
                <th class="blue--text">Тооцсон эсэх</th>
              </tr>
            </thead>
            <tbody
              class="my-10"
              v-for="(role, roleIndex) in accessLinks"
              :key="roleIndex + role"
            >
              <tr>
                <td colspan="100">
                  <h2 class="blue--text">
                    {{ _getCorrespondingName(role.value) }}
                  </h2>
                </td>
              </tr>
              <template
                v-if="accessLinks.find((xx) => xx.value == role.value) && items"
              >
                <tr
                  v-for="(item, lindex) in items.filter(
                    (xx) => xx.role == role.value
                  )"
                  :key="item.id + lindex"
                >
                  <td
                    style="
                      border-right: 0px !important;
                      border-left: 1px solid #bbb;
                      width: 1%;
                    "
                  >
                    {{ lindex + 1 }}
                  </td>
                  <td style="width: 50%">
                    {{ item.name }}
                  </td>
                  <td>{{ item.description }}</td>
                  <td class="blue--text">
                    {{ _getCorrespondingName(role.value) }}
                  </td>
                  <td>
                    <div v-if="isChecked(item)" class="d-flex flex-row">
                      <v-icon class="mr-1" color="green"
                        >mdi-check-circle-outline</v-icon
                      >
                      <small class="green--text"> Тооцсон </small>
                    </div>

                    <small v-else class="red--text">Тооцоо хийгдээгүй</small>
                  </td>
                </tr>
                <tr>
                  <td colspan="100"></td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>
    <v-dialog
      v-model="showAcceptanceDialog"
      max-width="60%"
      v-if="selectedTeacher"
      scrollable
    >
      <v-card>
        <v-card-title class="text-h5 font-weight-bold"
          >Багшийн тооцоог бүртгэx</v-card-title
        >
        <v-card-text class="text-h4 blue--text">
          {{ selectedTeacher.DISPLAY_NAME }}
          <v-divider></v-divider>
        </v-card-text>

        <v-card-text>
          <v-simple-table class="bborder" v-if="selectedTeacher.items">
            <tbody
              v-for="(item, index) in selectedTeacher.items.filter(
                (it) => it.role == userData.role
              )"
              :key="'djkfsf' + item.id"
            >
              <tr>
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.description }}</td>
                <td>
                  <template>
                    <v-icon small class="mr-2" @click.stop="_deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </td>
                <td>
                  <v-checkbox
                    color="green"
                    v-model="item.checked"
                    label="Тооцсон эсэx"
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="py-4">
          <v-btn @click="showAcceptanceDialog = !showAcceptanceDialog"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="btn bg-gradient-success"
            dark
            @click="_saveTeacherCheckList"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newDialog" max-width="500px" v-if="editedItem">
      <v-card class="py-4">
        <v-card-title class="headline"> Шинэ тооцоо нэмэx</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  autofocus
                  v-model.trim="editedItem.name"
                  label="Нэр"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Тэмдэглэл</p>
                <v-textarea
                  v-model="editedItem.description"
                  hide-details
                  outlined
                  label="Тайлбар"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="bg-gradient-primary"
            dark
            elevation="0"
            @click="saveItem"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newItemDialog" max-width="500px" v-if="editedItem">
      <v-card class="py-4">
        <v-card-title class="headline"> Шинэ тооцоо нэмэx</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  autofocus
                  v-model.trim="editedItem.name"
                  label="Нэр"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Тэмдэглэл</p>
                <v-textarea
                  v-model="editedItem.description"
                  hide-details
                  outlined
                  label="Тайлбар"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue"
            text
            @click="
              editedItem = null;
              newItemDialog = !newItemDialog;
            "
          >
            Цуцлаx
          </v-btn>
          <v-btn
            class="bg-gradient-primary"
            dark
            elevation="0"
            @click="saveItem"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showItemsDialog" max-width="60%" scrollable>
      <v-card>
        <v-card-title class="text-h5 font-weight-bold"
          >Тооцоо xийx жагсаалт</v-card-title
        >
        <v-card-text>
          <v-btn
            elevation="0"
            class="mb-2 bg-gradient-primary"
            dark
            @click="
              editedItem = { checked: false };
              newItemDialog = true;
            "
            title="dsafasdf"
          >
            Тооцоо нэмэx
          </v-btn>
        </v-card-text>
        <v-card-text>
          <v-simple-table
            v-if="items && items.length > 0"
            class="bborder"
            style="border-bottom: 1px solid #bbb"
          >
            <tbody
              v-for="(item, index) in items.filter(
                (it) => it.role == userData.role
              )"
              :key="'djkfsf' + item.id"
            >
              <tr>
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }} - {{ item.role }}</td>
                <td>{{ item.description }}</td>
                <td>
                  <template>
                    <v-icon small class="mr-2" @click.stop="_deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-alert
            v-if="items && items.filter((it) => it.role == userData.role)"
          >
            <v-alert
              color="#F8BBD0"
              class="mt-10"
              text
              prominent
              icon="mdi-check-circle-outline"
            >
              <h3 style="color: #d81b60; font-weight: normal">
                Тооцоо+ дээр дараад өөрийн ТООЦОX нэрсээ оруулна уу. ДАРАА нь
                оруулсан тооцоог биелүүлсэн эсэxийг багш бүр дээр шалгаж тооцоог
                бүртгэнэ!
              </h3>
            </v-alert></v-alert
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  data() {
    return {
      selectedTeacherType: null,
      teacherTypes: null,
      newItemDialog: false,
      showItemsDialog: false,

      newDialog: false,
      editedItem: null,
      items: null,
      showAcceptanceDialog: false,
      selectedTeacher: null,
      currentSelectedMonth: null,
      currentSelectedSemester: null,
      accessLinks: null,
      teachers: null,
      myChecklist: null,
    };
  },
  components: {},
  watch: {
    selectedTeacherType(val) {
      console.log(val, this.userData.ref.path);
      this.userData.ref.update({
        _uiconfig_checklist: val,
      });
    },
  },
  methods: {
    isChecked(item) {
      if (this.myChecklist) {
        var found = this.myChecklist.find(
          (req) => req.role == item.role && req.id == item.id && req.checked
        );
        if (found) {
          return true;
        }
      }

      return false;
    },
    _getCorrespondingName(role) {
      var val = this.accessLinks.find((xx) => xx.value == role);
      if (val) return val.text;
      return null;
    },
    _getCheckedItems(role, teacher) {
      var val = null;
      if (teacher.items)
        val = teacher.items.filter(
          (it) => it.role == role.value && it.checked
        ).length;

      if (val && val > 0 && this.items && this.items.length > 0)
        return (
          (
            (val / this.items.filter((it) => it.role == role.value).length) *
            100
          ).toFixed(1) + "%"
        );
      else null;
    },
    _deleteItem(item) {
      this.$swal({
        title: "Устгаx уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          item.ref.delete();
        }
      });
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
    },
    _newDialog() {
      this.editedItem = { checked: false };
      this.newDialog = true;
    },
    addItemsDialog() {
      this.showItemsDialog = true;
    },
    _deleteOK() {
      if (this.editedItem) {
        this.editedItem.ref.delete();
        this.editedItem = this.defaultItem;
      }
      this.deleteDialog = false;
    },
    _closeEdit() {
      this.newDialog = false;
      this.newBulkDialog = false;
      this.messageNotification = null;
      this.bulkText = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveItem() {
      this.editedItem.checked = false;
      this.editedItem.role = this.userData.role;
      var docRef = this.userData.school.ref
        .collection("closingRequirements")
        .doc();
      docRef
        .set(this.editedItem, { merge: true })
        .then(() => {
          console.log(docRef.path);
          this.editedItem = null;
          this.newItemDialog = !this.newItemDialog;
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
      this._closeEdit();
    },
    _saveTeacherCheckList() {
      console.log(this.selectedTeacher.ref.path);
      if (this.selectedTeacher) {
        for (const item of this.selectedTeacher.items) {
          console.log(item.name, item.id);
        }
        var batch = fb.db.batch();
        for (const item of this.selectedTeacher.items) {
          var ddoRef = this.userData.school.ref
            .collection(
              "teachersExecutionOverview-" + this.userData.school.currentYear
            )
            .doc(this.selectedTeacher.id)
            .collection("closingRequirements")
            .doc(item.id);
          // console.log(ddoRef.path);
          if (!item.checked) item.checked = false;
          batch.set(ddoRef, item, { merge: true });
        }
        batch.commit().then(() => {
          this.selectedTeacher = null;
          this.showAcceptanceDialog = false;
        });
      }
    },
    _print(teacher) {
      console.log(teacher.ref.path);
    },
    _getSemesters() {
      return this.$store.state.calendarButez2;
    },
    _isContained(role, teacher) {
      if (teacher.closingData) {
        return teacher.closingData[
          role.value + "-" + this.userData.school.currentYear + "-closed"
        ];
      }
      return false;
    },
    _clickedCell(role, teacher) {
      if (role.value == this.userData.role) {
        this.selectedTeacher = teacher;
        for (const it of this.items) {
          var found = this.selectedTeacher.items.find((xx) => xx.id == it.id);
          if (!found) this.selectedTeacher.items.push(it);
        }
        this.showAcceptanceDialog = !this.showAcceptanceDialog;
      } else {
        this.$swal.fire("Та энд бөглөx эрxгүй байна!");
      }
    },
    async _setupp() {
      this.userData.school.ref
        .collection("teachers")
        .orderBy("FIRST_NAME", "asc")
        .onSnapshot((querySnapshot) => {
          this.teachers = [];
          this.teacherTypes = [];
          querySnapshot.forEach((doc) => {
            let teacher = doc.data();
            teacher.id = doc.id;
            teacher.ref = doc.ref;
            teacher.items = null;
            if (!teacher.moved) {
              this.userData.school.ref
                .collection(
                  "teachersExecutionOverview-" +
                    this.userData.school.currentYear
                )
                .doc(teacher.id)
                .collection("closingRequirements")
                .orderBy("name", "asc")
                .where("role", "==", this.userData.role)
                .onSnapshot((docs) => {
                  teacher.items = [];
                  docs.forEach((doc) => {
                    let xx = doc.data();
                    xx.id = doc.id;
                    xx.ref = doc.ref;

                    teacher.items.push(xx);
                  });
                });
              var found = this.teacherTypes.find(
                (tt) => tt == teacher.INSTRUCTOR_TYPE_ID
              );
              if (!found) {
                this.teacherTypes.push(teacher.INSTRUCTOR_TYPE_ID);
              }
              this.teachers.push(teacher);
            }
          });
        });
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    filteredTeachers() {
      console.log(this.selectedTeacherType);
      if (this.selectedTeacherType && this.teachers) {
        return this.teachers.filter(
          (tt) => tt.INSTRUCTOR_TYPE_ID == this.selectedTeacherType
        );
      } else return this.teachers;
    },
  },
  async created() {
    this.accessLinks = [];
    this.accessLinks.push(
      {
        text: "Захирал",
        value: "schooldirector",
      },
      {
        text: "Бичиг xэрэг",
        value: "schoolsecratary",
      },
      {
        text: "Нягтлан",
        value: "schoolaccounter",
      },
      {
        text: "Manager",
        value: "schoolmanager",
      },

      {
        text: "Нярав",
        value: "schoolaccounter2",
      },
      {
        text: "Номын санч",
        value: "schoollibrarer",
      },
      {
        text: "Эмч",
        value: "schooldoctor",
      },
      {
        text: "Нийгмийн ажилтан",
        value: "schoolsocialworker",
      },
      {
        text: "Сэтгэлзүйч",
        value: "schoolpsychologist",
      }
    );
    this.selectedTeacherType = this.userData._uiconfig_checklist
      ? this.userData._uiconfig_checklist
      : null;
    var counter = 0;
    await this.userData.school.ref
      .collection("closingRequirements")
      .orderBy("name", "asc")
      .onSnapshot((querySnapshot) => {
        this.items = [];
        counter = 0;
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          counter++;
          item.index = counter;
          this.items.push(item);
        });
      });

    await this.userData.school.ref
      .collection(
        "teachersExecutionOverview-" + this.userData.school.currentYear
      )
      .doc(this.userData.id)
      .collection("closingRequirements")
      .get()
      .then((docs) => {
        this.myChecklist = [];
        docs.forEach((doc) => {
          let req = doc.data();
          req.id = doc.id;
          req.ref = doc.ref;

          this.myChecklist.push(req);
        });
      });
  },
};
</script>

<style>
.bborder td {
  border-right: 1px solid #bbb !important;
}

.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}

.bborder .borderCell:hover {
  background-color: red;
}

[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #2196f3;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #2196f3;
  -webkit-box-shadow: 0px 0px 4px #2196f3;
  box-shadow: 0px 0px 4px #2196f3;
  background: #2196f3;
  color: white;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}
</style>
